.SpeedyClaims {
  padding: 30px 0px;
}
.SpeedyClaims .main-content .sub-content {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 80%;
}
.SpeedyClaims .main-content .heading {
  font-size: 44px;
  color: #333;
  font-weight: normal;
}
.SpeedyClaims .main-content .content {
  margin: 40px 0px;
}
.SpeedyClaims .main-content .content .sub-Heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}
.SpeedyClaims .main-content .content .sub-Heading span {
  margin-right: 5px;
}
.SpeedyClaims .main-content .content .desc {
  font-size: 16px;
  padding-left: 25px;
}
.SpeedyClaims .main-content .content .cta {
  background-color: #ff5b0b;
  border-radius: 15px;
  color: #fff !important;
  display: block;
  padding: 10px;
  font-weight: 700;
  width: 50%;
  text-align: center;
  text-decoration: none;
  margin-left: 25px;
  cursor: pointer;
}
.SpeedyClaims .main-content .content .cta.TellMeMore {
  background-color: transparent;
  border: 1px solid #ff5b0b;
  color: #ff5b0b !important;
}
.SpeedyClaims .main-content .content .cta:hover {
  text-decoration: none !important;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .SpeedyClaims .main-content .sub-content {
    background-position-x: 100%;
    background-size: auto 400px;
  }
}
@media (max-width: 767.98px) {
  .SpeedyClaims .main-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .SpeedyClaims .main-content .heading {
    font-size: 30px;
    padding: 0px 15px;
  }
  .SpeedyClaims .main-content .sub-content {
    background-image: none !important;
  }
  .SpeedyClaims .main-content .sub-content .content {
    margin: 15px;
  }
  .SpeedyClaims .main-content .sub-content .content .sub-Heading {
    font-size: 18px;
    font-weight: bold;
  }
  .SpeedyClaims .main-content .sub-content .content .desc {
    font-size: 16px;
  }
  .SpeedyClaims .main-content .sub-content .content .cta {
    width: 100%;
    font-size: 16px;
    margin-left: 0px;
  }
  .SpeedyClaims .main-content .sub-content .imgDiv {
    text-align: center;
  }
  .SpeedyClaims .main-content .sub-content .imgDiv .speedyClaimsImg {
    width: 70%;
    margin: 30px 0px;
  }
}

.tellMeMoreModal {
  color: #303030;
}
.tellMeMoreModal .row {
  margin-top: 15px;
}
.tellMeMoreModal .title, .tellMeMoreModal .bold {
  font-size: 18px;
  font-weight: bold;
}
.tellMeMoreModal .content {
  font-size: 18px;
}
.tellMeMoreModal .modal-dialog.message-modal {
  max-width: 40% !important;
}
.tellMeMoreModal .message-header-container {
  color: #303030 !important;
}
.tellMeMoreModal .modal-header .close {
  margin-right: 0px;
}
.tellMeMoreModal .modal-body {
  padding-right: 30px !important;
}

