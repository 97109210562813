@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

/* ==================================== Header CSS ====================================*/
.pet_detail_header {

    height: 55px;

    @include media-breakpoint-up(md) {
        height: 80px;
    }

    @include media-breakpoint-up(lg) {
        height: 100px;
    }
}

.add_pet_button {
    width: 239px;
    height: 40px;
}

/* ==================================== PetDetail List Item CSS ====================================*/
.detail-title {
    color: $dark;
    margin: 0px;

    padding-right: 0px;
    padding-left: 0px;

    display: table-cell;
    vertical-align: middle;

    line-height: 1.0;
}

.detail-value {
    text-align: right;

    display: table-cell;
    vertical-align: middle;

    padding-right: 0px;
    padding-left: 0px;

    font-size: 14px;
    font-weight: 400;

    @include media-breakpoint-up(md) {
        font-size: 16px;
        text-align: left;
    }

    color: $dark;
}

/* ==================================== Waiting Period CSS ====================================*/
.waiting_period {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.waiting_period_text {
    color: $text-Blue;
    margin-bottom: 4px;

    font-size: 12px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: 14px;
        font-weight: 600;
    }
}

.waiting_terms {
    font-weight: 400;
    color: $dark;
    font-size: 12px;
    line-height: normal;
    padding-left: 18px ;
    
}