.topAlertBanner {
  position: fixed;
  top: 0px;
  display: none;
  z-index: 100000;
  width: 100%;
  background-color: #666666;
  align-items: center;
  justify-content: center;
  min-height: 45px;
}
.topAlertBanner.open {
  display: flex;
}

.zeromargin {
  margin-top: 20px;
}

.topAlertBannerContainer {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
@media (max-width: 991.98px) {
  .topAlertBannerContainer {
    padding: 5px 7px;
  }
}
.topAlertBannerContainer div.topAlertBannerButton {
  display: inline-block;
  padding: 0px 8px;
  position: relative;
}
.topAlertBannerContainer div.topAlertBannerButton .xButton {
  font-size: 24px;
  margin: auto;
  cursor: pointer;
}
.topAlertBannerContainer .bat-signal-warning-img {
  width: 17px;
  height: 15px;
  align-self: flex-start;
  margin-top: 14px;
}
.topAlertBannerContainer .topAlertText {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  text-align: left;
  padding: 12px 2px 12px 8px;
  max-width: calc(100% - 120px);
}
.topAlertBannerContainer .dismissButton {
  background-color: #666666;
  color: #ffffff;
  width: 99px;
  height: 30px;
  padding: 2px 2px 2px 2px;
  display: inline-block;
  font-size: 14px;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
  font-weight: 600;
  text-align: center;
  border-radius: 26px;
  border: 1px solid #ffffff;
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .topAlertBannerContainer .dismissButton {
    height: 36px;
    align-self: flex-start;
    margin-top: 14px;
  }
}

.top-banner-more-details {
  font-weight: normal;
  font-size: 12px;
  text-decoration: underline !important;
  color: #FFFFFF !important;
}

.hidden {
  display: none;
}

