.title-container {
  height: 50px;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .title-container {
    height: 79px !important;
  }
}
@media (min-width: 992px) {
  .title-container {
    height: 97px !important;
  }
}

.select-type {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: 15px !important;
}

.margin-bottom-half {
  margin-bottom: 7px !important;
}

a {
  text-decoration: none;
}

hr {
  height: 2px;
  background: #E4E4E4;
  margin: 0px;
  border-top: 0;
}

.faq-container {
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
}

.edit-reimbrusement-container {
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
}
.edit-reimbrusement-container .tab-parent {
  margin: 15px;
  max-width: 384px;
}
.edit-reimbrusement-container .tab-parent .unselected-tab {
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(52, 163, 80);
  box-shadow: none;
  font-weight: 700;
  font-size: 16px;
  color: #34A350;
}
.edit-reimbrusement-container .tab-parent .selected-tab {
  background-color: rgb(52, 163, 80);
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(18, 105, 47);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4862745098) inset;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
}
.edit-reimbrusement-container .tab-parent .left-tab {
  height: 44px;
  margin-bottom: 0;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 0;
  padding-right: 0;
}
.edit-reimbrusement-container .tab-parent .right-tab {
  height: 44px;
  margin-bottom: 0;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 0;
  padding-right: 0;
}
.edit-reimbrusement-container .billing-notes {
  background-color: rgb(242, 242, 242);
  padding: 15px;
  margin-bottom: 0;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
}
@media (min-width: 992px) {
  .edit-reimbrusement-container .billing-notes {
    font-size: 14px;
  }
}

.removestepperheader .stepper-header {
  display: none;
}

.link-text {
  font-size: 14px;
  font-weight: 700;
}

.link-icon {
  font-size: 13px;
}

.account-info-image {
  height: 105px;
  object-fit: contain;
}

.account-info-heading-text {
  color: rgb(51, 51, 51);
  margin-bottom: 0px;
}

.account-info-margin {
  margin-top: 22px;
}

.close-button {
  width: 238px;
}

.GreyBox {
  background-color: #eee;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.greyboxpoints {
  padding-inline-start: 20px;
}

.update-success .content .success-container {
  margin-top: 10px;
}

.MailorDirect {
  margin-top: -15px;
}

@media (max-width: 991px) {
  .success-view {
    min-height: 0px;
  }
  .update-success {
    min-height: 0px !important;
    padding-bottom: 0px;
  }
  .update-success .content h1 {
    margin-top: 10px;
  }
  .mailingsuccess .mailingbody {
    margin-top: 5px;
    margin-left: -10px;
  }
}
@media (min-width: 992px) {
  .faqwidget {
    margin-top: 110px !important;
  }
}

