.status-header-container #status-header {
  height: 42px !important;
}

.review-rates {
  color: #28373A !important;
  padding: 30px 15px 0 15px;
}
.review-rates .discountsApplied {
  color: #038062;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .review-rates .discountsApplied {
    background-color: #4aa574;
    color: #fff;
    padding: 10px 0px;
    text-align: center;
    margin-top: 10px;
  }
}
.review-rates .discountsApplied img {
  width: 21px;
  height: 18px;
  margin-right: 5px;
}
.review-rates .next-arrow-circle-right-custom {
  height: 25px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.review-rates .pencil-edit-custom {
  height: 28px;
}
.review-rates .fa-pencil {
  font-size: 28px;
}
.review-rates .customize-rate-link {
  font-size: 13px;
  color: #6B61F2 !important;
  cursor: pointer;
}
.review-rates .customize-rate-link:hover {
  text-decoration: underline;
}
.review-rates h1 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 36px !important;
  color: #28373a !important;
}
@media (max-width: 991.98px) {
  .review-rates h1 {
    font-size: 21px !important;
  }
}
.review-rates .text-medium {
  color: #28373A !important;
}
.review-rates .link-text {
  color: #6b61f2 !important;
}
.review-rates .text-dark {
  color: #28373a !important;
}
.review-rates .rate-text {
  color: #333333;
  font-size: 30px;
  font-weight: bold;
}
.review-rates .rate-text small {
  font-size: 12px;
  font-weight: normal;
}
.review-rates .pet-icon {
  width: 43px;
  height: 36px;
}
.review-rates .plan-selector-container {
  border: 2px solid #E4E4E4;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.review-rates .review-sub-content {
  padding: 25px;
}
.review-rates .review-sub-content .text-primary {
  color: #28373a !important;
}
.review-rates .review-sub-content.shaded {
  background-color: #eff3f5;
}
.review-rates .arrow_box {
  position: relative;
  background: transparent;
  border-right: 2px solid #E4E4E4;
}
.review-rates .arrow_box:after, .review-rates .arrow_box:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.review-rates .arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #F7F7F7;
  border-width: 9px;
  margin-top: -9px;
  margin-left: -1px;
}
.review-rates .arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #E4E4E4;
  border-width: 11px;
  margin-top: -11px;
}
.review-rates .new-rate-container {
  border: 2px solid #F0F0EE;
  margin-top: -2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.review-rates .new-rate-container .calendar-icon {
  font-size: 24px;
  color: #333333;
  padding-right: 15px;
}
.review-rates .new-rate-container .rate-container {
  padding: 15px;
}
.review-rates .new-rate-container .rate-container h2 {
  color: #28373a !important;
  font-weight: 400 !important;
}
.review-rates .new-rate-container .rate-container h4 {
  color: #28373a !important;
  font-weight: 700 !important;
}
.review-rates .new-rate-container .rate-container a .button-link:hover {
  text-decoration: underline !important;
}
.review-rates .new-rate-container .effective-details {
  background-color: #F7F7F7;
  padding: 15px;
}
@media (max-width: 991.98px) {
  .review-rates .new-rate-container .effective-details {
    background-color: transparent;
    border-top: 1px solid #E4E4E4;
  }
}
.review-rates .new-rate-container .effective-details .effective-date {
  font-size: 14px;
}
.review-rates .new-rate-container .effective-details ul {
  padding-inline-start: 16px;
  font-size: 14px;
}
.review-rates .sticky-bottom {
  margin-top: 15px;
}
.review-rates .rate-text-large {
  font-size: 30px;
  font-weight: bold;
}
.review-rates .rate-text-large small {
  font-size: 12px;
  font-weight: normal;
}

@media (min-width: 768px) {
  .review-rates {
    padding: 30px 15px;
  }
  .review-rates .rate-container {
    padding: 25px;
  }
  .review-rates .effective-details {
    padding: 25px;
    background-color: #E4E4E4;
    padding: 15px;
  }
  .review-rates .button-container {
    width: 50%;
    margin: 0 auto;
  }
  .review-rates .button-container .button-link {
    color: #6b61f2;
  }
  .review-rates .rate-text-large {
    font-size: 38px;
    font-weight: bold;
  }
  .review-rates .rate-text-large small {
    font-size: 14px;
    font-weight: normal;
  }
}
@media (min-width: 992px) {
  .review-rates {
    padding-top: 0 !important;
  }
  .review-rates .new-rate-container {
    border: 2px solid #4aa574;
    border-radius: 8px;
    margin: 0;
    padding: 25px;
  }
  .review-rates .new-rate-container .rate-container {
    padding: 0;
  }
  .review-rates .new-rate-container .effective-details {
    padding: 0;
    background-color: transparent;
  }
  .review-rates .new-rate-container .button-container {
    width: 100%;
  }
}

