@import "../../../styles/variables";
@import "../../../styles/mixins";


.status-header-container #status-header{
    height: 42px !important;
}
.review-rates {
    padding: 30px 15px 0 15px;
    .discountsApplied{
        color:#038062;
        font-size:14px;
        font-weight:600;
        

        @media only screen and (max-width: 767px){
            background-color:#4aa574;
            color:#fff;
            padding:10px 0px;
            text-align: center;
            margin-top:10px;
        }
        img{
            width:21px;
            height:18px;
            margin-right:5px;
        }
    }
    .rate-text {
        color: $dark;
        font-size: 30px;
        font-weight: bold;
        small {
            font-size: 12px;
            font-weight: normal;
        }
    }

    .pet-icon {
        width:43px;
        height:36px;
    }

    .plan-selector-container {
        border:2px solid $light;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .review-sub-content {
        padding:25px;
        @media only screen and (max-width: 767px){
           
            padding:25px 0px !important;
        }


        &.shaded {
            background-color: #F7F7F7;
        }

        &.monthlyRate{
            @media only screen and (min-width: 992px) and (max-width:1030px){
           
                padding:25px 10px !important;
            }
        }
    }

    .arrow_box {
        position: relative;
        background: transparent;
        border-right : 2px solid #E4E4E4;
 
        &:after, &:before {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        
        &:after {
            border-color: rgba(136, 183, 213, 0);
            border-left-color: #F7F7F7;
            border-width: 9px;
            margin-top: -9px;
            margin-left: -1px;
        }
        &:before {
            border-color: rgba(0, 0, 0, 0);
            border-left-color: #E4E4E4;
            border-width: 11px;
            margin-top: -11px;
        }
    }

    .new-rate-container {
        border: 2px solid $gray-1;
        margin-top:-2px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    
        .calendar-icon {
            font-size: 24px;
            color: $dark;
            padding-right: 15px;
        }
        .rate-container {
            padding: 15px;
            a{
              .button-link:hover {
                text-decoration: underline !important;
              }  
            }
        }
        .effective-details{
            background-color:#F7F7F7;
            padding: 15px;
            
            .effective-date {
                font-size: 14px;
            }

            ul {
                padding-inline-start: 16px;
                font-size: 14px;
            }

            @include media-breakpoint-down(md) {
                ul {
                    margin-inline-start: -30px;
                }
            }
        }
    }
    .sticky-bottom {
        margin-top: 15px;
    }
    .rate-text-large {
        font-size: 30px;
        font-weight: bold;
        small {
            font-size: 12px;
            font-weight: normal;
        }
    }
}

@include media-breakpoint-up(md) {
    .review-rates {
        padding: 30px 15px;
        .rate-container {
            padding: 25px;
        }
        .effective-details{
            padding: 25px;
            background-color:$light;
            padding: 15px;
        }
        .button-container {
            width: 50%;
            margin: 0 auto;
        }
        .rate-text-large {
            font-size: 38px;
            font-weight: bold;
            small {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .review-rates {
        .new-rate-container {
            border: 2px solid $secondary;
            border-radius: 8px;
            margin: 0;
            padding: 25px;

            .rate-container {
                padding: 0;
            }
            .effective-details {
                padding: 0;
                background-color:transparent;
            }
            .button-container {
                width: 100%;
            }
        }
        padding-top: 0 !important;
    }
}