@import "./../../styles/_mixins.scss";
@import "./../../styles/_variables.scss";



    .title-container {
        height: 50px;
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            height: 79px !important;
        }

        @include media-breakpoint-up(lg) {
            height: 97px !important;
        }
    }

    .select-type {
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0;
    }

    .margin-bottom {
        margin-bottom: 15px !important;
    }

    .margin-bottom-half {
        margin-bottom: 7px !important;
    }

    a {
        text-decoration: none;
    }

    hr {
        height: 2px;
        background: $light;
        margin: 0px;
        border-top: 0;
    }

    .faq-container {
        border-width: 2px;
        border-style: solid;
        border-color: $light;
        border-radius: 8px;
    }

    .edit-reimbrusement-container {
        border-width: 2px;
        border-style: solid;
        border-color: $light;
        border-radius: 8px;

        .tab-parent {
            margin: 15px;
            max-width: 384px;

            .unselected-tab {
                background-color: rgba(255, 255, 255, 1);
                box-sizing: border-box;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(52, 163, 80, 1);
                box-shadow: none;
                font-weight: 700;
                font-size: 16px;
                color: $primary;
            }

            .selected-tab {
                background-color: rgba(52, 163, 80, 1);
                box-sizing: border-box;
                border-width: 2px;
                border-style: solid;
                border-color: rgba(18, 105, 47, 1);
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.486274509803922) inset;
                font-weight: 700;
                font-size: 16px;
                color: #FFFFFF;
            }

            .left-tab {
                height: 44px;
                margin-bottom: 0;
                border-radius: 8px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding-left: 0;
                padding-right: 0;
            }

            .right-tab {
                height: 44px;
                margin-bottom: 0;
                border-radius: 8px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .billing-notes {
            background-color: rgba(242, 242, 242, 1);
            padding: 15px;
            margin-bottom: 0;
            font-weight: 400;
            color: #000000;
            font-size: 12px;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
        }
    }
    .removestepperheader .stepper-header{
        display: none;
    }

    .link-text {
        font-size: 14px;
        font-weight: 700;
    }

    .link-icon {
        font-size: 13px;
    }

.account-info-image {
    //width: 306px;
    height: 105px;
    object-fit: contain;
}

.account-info-heading-text {
    color: rgb(51, 51, 51);
    margin-bottom: 0px;
}
.account-info-margin {
    margin-top: 22px;
}

.close-button {
    width: 238px;
}
.GreyBox{
    background-color: #eee;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}
.greyboxpoints{
    padding-inline-start: 20px;
}
.update-success .content .success-container{
    margin-top: 10px;
}
.MailorDirect{
    margin-top: -15px;
}
@media (max-width: 991px) {
    .success-view{
        min-height: 0px;
    }
    .update-success{
        min-height: 0px !important;
        padding-bottom: 0px;
        .content{
            h1{
                margin-top: 10px;
            }
        }
    }
    .mailingsuccess{
        .mailingbody{
            margin-top: 5px;
            margin-left: -10px;
        }
    }
}
@media (min-width: 992px){
    .faqwidget{
        margin-top: 110px !important;
    }
}