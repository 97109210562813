@import "../../../styles/variables";
@import "../../../styles/mixins";

.status-header-container #status-header{
    height: 42px !important;
}
.review-rates {
    color: #28373A !important;

    .discountsApplied{
        color:#038062;
        font-size:14px;
        font-weight:600;
        

        @media only screen and (max-width: 767px){
            background-color:#4aa574;
            color:#fff;
            padding:10px 0px;
            text-align: center;
            margin-top:10px;
        }
        img{
            width:21px;
            height:18px;
            margin-right:5px;
        }
    }
    
    .next-arrow-circle-right-custom {
        height: 25px;
        margin-left: 10px;
        padding-bottom: 1px;
    }

    .pencil-edit-custom {
        height: 28px;
    }


    .fa-pencil {
        font-size: 28px;
    }

    .customize-rate-link {
        font-size: 13px;
        color: #6B61F2 !important;
        cursor: pointer;
    }

    .customize-rate-link:hover {
        text-decoration: underline;
    }

    h1 {
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        font-weight: 400 !important;
        font-style: normal !important;
        font-size: 36px !important;
        color: $header-Color !important;
        @include media-breakpoint-down(md){
            font-size:21px !important;
        }
    }

    .text-medium {
        color: #28373A !important;
    }

    .link-text {
        color: $text-Blue !important;
    }

    .text-dark {
        color: $header-Color !important;
    }

    padding: 30px 15px 0 15px;

    .rate-text {
        color: $dark;
        font-size: 30px;
        font-weight: bold;

        small {
            font-size: 12px;
            font-weight: normal;
        }
    }

    .pet-icon {
        width: 43px;
        height: 36px;
    }

    .plan-selector-container {
        border: 2px solid $light;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .review-sub-content {
        padding: 25px;
        .text-primary{
            color:$header-Color !important;
        }
        &.shaded {
            background-color: $header-active;
        }
    }

    .arrow_box {
        position: relative;
        background: transparent;
        border-right: 2px solid #E4E4E4;

        &:after, &:before {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(136, 183, 213, 0);
            border-left-color: #F7F7F7;
            border-width: 9px;
            margin-top: -9px;
            margin-left: -1px;
        }

        &:before {
            border-color: rgba(0, 0, 0, 0);
            border-left-color: #E4E4E4;
            border-width: 11px;
            margin-top: -11px;
        }
    }

    .new-rate-container {
        border: 2px solid $gray-1;
        margin-top: -2px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .calendar-icon {
            font-size: 24px;
            color: $dark;
            padding-right: 15px;
        }

        .rate-container {
            padding: 15px;
            h2{
                color:$header-Color !important;
                font-weight:400 !important
            }
            h4{
                color:$header-Color !important;
                font-weight:700 !important
            }

            a {
                .button-link:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .effective-details {
            background-color: #F7F7F7;
            padding: 15px;
            @include media-breakpoint-down(md){
                background-color: transparent;
                border-top:1px solid $light;
            }
            .effective-date {
                font-size: 14px;
            }

            ul {
                padding-inline-start: 16px;
                font-size: 14px;
            }
        }
    }

    .sticky-bottom {
        margin-top: 15px;
    }

    .rate-text-large {
        font-size: 30px;
        font-weight: bold;

        small {
            font-size: 12px;
            font-weight: normal;
        }
    }
}

@include media-breakpoint-up(md) {
    .review-rates {
        padding: 30px 15px;
        .rate-container {
            padding: 25px;
        }
        .effective-details{
            padding: 25px;
            background-color:$light;
            padding: 15px;
        }
        .button-container {
            width: 50%;
            margin: 0 auto;
            .button-link{
                color:$text-Blue;
            }
        }
        .rate-text-large {
            font-size: 38px;
            font-weight: bold;
            small {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .review-rates {
        .new-rate-container {
            border: 2px solid $button_Green;
            border-radius: 8px;
            margin: 0;
            padding: 25px;

            .rate-container {
                padding: 0;
            }
            .effective-details {
                padding: 0;
                background-color:transparent;
            }
            .button-container {
                width: 100%;
            }
        }
        padding-top: 0 !important;
    }
}